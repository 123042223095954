<template>
  <div class="w-full p-2">
    <div class="p-2 border-b-2 border-t-2">
      <div class="flex justify-between items-center">
        <div class="text-bold text-xl text-gray-800">{{ category.label }}</div>
        <button
          @click="toggleSeeMore"
          class="text-md text-gray-600">
          <span v-if="expand">Tutup</span>
          <span v-else>Lihat</span>
        </button>
      </div>
    </div>
    <div v-if="expand">
      <Contents :contents="contentsData" />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { defineAsyncComponent } from 'vue'
const Contents = defineAsyncComponent(() => import('@/components/document/Contents.vue'))
export default {
  name: 'CategoriesItem',
  components: {
    Contents
  },
  data () {
    return {
      expand: false,
      contentsData: []
    }
  },
  props: {
    category: {
      required: true
    }
  },
  methods: {
    ...mapActions('pdf', ['fetchContents']),
    toggleSeeMore () {
      this.expand = !this.expand
      if (this.contentsData.length === 0) {
        this.fetch()
      }
    },
    fetch () {
      this.fetchContents({
        categoryId: this.category.id,
        success: this.setContentsData
      })
    },
    setContentsData (data) {
      this.contentsData = [...data]
    }
  }
}
</script>

<style scoped>

</style>
